.video-gallery {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  background-color: rgb(217, 208, 208);
  /* margin: 60px; */
  display: flex;
  /* justify-content: center; */
}

[data-ui-id='stream-media-container'] {
  justify-content: center;
}

.media-parent-container {
  width: 100% !important;
}

.no-active-speaker {
  width: 100%;
  margin: auto;
  text-align: center;
  font-weight: bold;
}
